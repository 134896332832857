<template>
  <div>
    <section class="page-title text-center mb-5">
      <div class="container">
        <div class="content-box">
          <h1>
            {{ $t('update my account') }}
          </h1>
        </div>
      </div>
    </section>
    <div style="background-color: white; padding-top: 36px; padding-bottom: 150px">
      <div class="container">
        <form method="post" class="row" @submit.prevent="save()">
          <div class="col-md-2">
            <div class="mb-3">
              <label for="sexType" class="form-label">
                {{ $t("Type") }} <b style="color: red;">*</b>
              </label>

              <b-form-select v-model="body.sexType" class="mb-3">
                <b-form-select-option value="MR">{{
                  $t("MR")
                }}</b-form-select-option>
                <b-form-select-option value="MRS">{{
                  $t("MRS")
                }}</b-form-select-option>
              </b-form-select>
            </div>
          </div>
          <div class="col-md-5">
            <div class="mb-3">
              <label for="fname" class="form-label">
                {{ $t("First Name") }} <b style="color: red;">*</b></label>
              <input type="text" class="form-control" id="fname" v-model="body.fname" />
            </div>
          </div>
          <div class="col-md-5">
            <div class="mb-3">
              <label for="lname" class="form-label">{{ $t("Last Name") }} <b style="color: red;">*</b></label>
              <input type="text" class="form-control" id="lname" v-model="body.lname" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="phone" class="form-label">{{ $t("Phone") }} <b style="color: red;">*</b></label>

              <vue-tel-input v-model.trim="body.phone" v-bind="{
                mode: 'international',
              }" :allCountries="allCountries"></vue-tel-input>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label for="email" class="form-label">{{
                $t("Email address")
              }}</label>
              <input type="email" class="form-control" id="email" v-model="body.email" placeholder="name@example.com" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label for="password" class="form-label">
                {{ $t("password") }}
              </label>
              <input type="password" class="form-control" id="password" v-model="body.password" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="cpassword" class="form-label">
                {{ $t("cpassword") }}
              </label>
              <input type="password" class="form-control" id="cpassword" v-model="body.cpassword" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label for="country" class="form-label">
                {{ $t("country") }}
              </label>
              <input type="text" class="form-control" id="country" v-model="body.country" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="city" class="form-label">
                {{ $t("city") }}
              </label>
              <input type="text" class="form-control" id="city" v-model="body.city" />
            </div>
          </div>

          <div class="col-md-10">
            <div class="mb-3">
              <label for="address" class="form-label">
                {{ $t("AddressHome") }}
              </label>
              <input type="text" class="form-control" id="address" v-model="body.address" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="mb-3">
              <label for="zip" class="form-label">
                {{ $t("zip") }}
              </label>
              <input type="text" class="form-control" id="zip" v-model="body.zip" />
            </div>
          </div>

          <div class="col-md-12">
            <b-button block type="submit" variant="danger" :disabled="!body.fname && !body.lname && !body.email && !body.password
              " style="background-color: #f26838; color: white">{{ $t("update") }}</b-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      body: {
        sexType: null,
        fname: null,
        lname: null,
        phone: null,
        password: null,

        cpassword: null,
        email: null,
        address: null,
        zip: null,

        country: null,
        city: null,
      },
      isInvalid: false,
      disabledAdd: false,
      allCountries: [
        ["Austria (Österreich)", "at", "43"],
        ["Germany (Deutschland)", "de", "49"],
        ["Switzerland (Schweiz)", "ch", "41"],
        ["Liechtenstein", "li", "423"],
      ].map(([name, iso2, dialCode]) => ({
        name,
        iso2: iso2.toUpperCase(),
        dialCode,
      })),
    };
  },

  methods: {
    save() {
      if (
        this.body.fname &&
        this.body.lname &&
        this.body.email &&
        this.disabledAdd == false
      ) {
        this.disabledAdd = true;

        this.$http.post("users/updateMyData", this.body).then(
          (res) => {
            this.disabledAdd = false;

            const user = JSON.parse(localStorage.userKB);
            localStorage.userKB = JSON.stringify({
              token: user.token,
              id: user.id,
              phone: res.data,
            });

            location.reload();
            this.$swal.fire({
              icon: "success",
              title: this.$t("Success"),
              text: this.$t("Success Done"),
              showConfirmButton: false,
              timer: 1500,
            });
          },
          (err) => {
            this.disabledAdd = false;

            this.$swal.fire({
              icon: "error",
              title: this.$t("Error"),
              text: err.response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$swal.fire({
          icon: "error",
          title: this.$t("Error"),
          text: this.$t("Fields must be filled out"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  async created() {
    this.$http.post("users/getProfile", {}).then((res) => {
      this.body = res.data.data;
    });
  },
};
</script>

<style></style>
